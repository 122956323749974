.label-enquiry{
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #024D93;
}

.contact-us-header {
  color: #024D93!important;
  background-color: white!important;
  font-family: Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0.01em;
  text-align: left;
}

.submit-button {
  float: right;
}