.parent-container {
  height: 200px;
  position: relative;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.text-font {
  font-family: Helvetica!important;
  color: #4F4F4F;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
}

.image-size {
  width: 15%;
}

@media only screen and (max-width: 979px) {
  .image-size {
    width: 30%!important;
  }
}

@media only screen and (max-width: 500px) {
  .image-size {
    width: 45%!important;
  }
}