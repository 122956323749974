.header {
  background: #024D93!important;
}

.header-text {
  font-family: Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #FFFFFF;
}

.header-body {
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #FFFFFF;
}