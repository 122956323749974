.nav-link{
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #024D93 !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  text-align: center!important;
}

.navbar-brand{
  margin-left: 2rem !important;
}