.title {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #024D93;
}

.vision-mission-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  letter-spacing: 0.01em;

  color: #000000;
}

.core-value-title {
  font-family: Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 37px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #024D93;
}

.core-value-text {
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.core-value {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 67% */

  letter-spacing: 0.01em;
}