.screen-width {
  width: 100%!important;
  margin: auto;
}

@media only screen and (min-width: 2000px) {
  .screen-width {
    width: 90%!important;
  }
}

@media only screen and (max-width: 2000px) {
  .screen-width {
    width: 100%!important;
  }
}